<template>
  <div>
    <base-section
      v-if="lotto && listaServizi.length > 0"
      id="gallery"
    >
      <v-tabs
        v-if="tabs"
        centered
        class="mb-12"
      >
        <v-tab
          v-for="(tab, i) in tabs"
          :key="i"
          :href="`#${tab.toLowerCase()}`"
          @click="currentTab = tab"
        >
          {{ $i18n.t(`tabs.${tab}.name`) }}
        </v-tab>
      </v-tabs>

      <v-container>
        <v-fade-transition
          group
          hide-on-leave
          tag="v-row"
        >
          <v-col
            v-for="(servizio, i) in servizi"
            :key="i"
            class="gallery-card"
            cols="12"
            sm="6"
            md="4"
          >
            <v-skeleton-loader
              v-if="servizio.waiting"
              class="mx-auto"
              max-width="300"
              max-height="375"
              type="card"
            />
            <service-card
              v-else
              v-bind="servizio"
              :src="require(`@/assets/imgs/services/${servizio.img}.png`)"
              :img-max-height="200"
              :name="servizio.nome"
              :url="servizio.url"
              :btn-text="$t(`tabs.${servizio.categoria}.btn`)"
              height="100"
              width="100%"
            />
          </v-col>
        </v-fade-transition>
      </v-container>
    </base-section>
    <base-section v-else-if="lotto && listaServizi.length === 0">
      <v-container>
        <v-fade-transition
          group
          hide-on-leave
          tag="v-row"
        >
          <v-col
            key="abc"
            class="d-flex align-center justify-center"
            style="text-align: center"
          >
            <h1
              class="pa-12"
            >
              {{ $i18n.t('no-services') }}
            </h1>
          </v-col>
        </v-fade-transition>
      </v-container>
    </base-section>
    <base-section v-else-if="view==='SERVIZI'">
      <v-container>
        <v-fade-transition
          group
          hide-on-leave
          tag="v-row"
        >
          <v-col
            key="abc"
            class="d-flex align-center justify-center"
            style="text-align: center"
          >
            <h1
              class="pa-12"
            >
              {{ $i18n.t((lottoErrato) ? 'lottoErrato' : 'qr') }}
            </h1>
          </v-col>
        </v-fade-transition>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  export default {
    name: 'SectionGallery',

    components: {
      ServiceCard: () => import('@/components/ServiceCard'),
    },

    data: () => ({
      listaServizi: [],
      lotto: null,
      tabs: ['all'],
      lottoErrato: false,
      currentTab: 'all',
    }),

    // **************
    // ** COMPUTED **
    // **************
    computed: {
      servizi () {
        return this.listaServizi.filter(servizio => servizio.categoria === this.currentTab || this.currentTab === 'all')
      },
      view () {
        return this.$route.meta.view
      },
    },
    created: async function () {
      this.lotto = this.$route.query.lotto || sessionStorage._idLotto
      if (this.lotto) {
        this.listaServizi = new Array(3)
        this.listaServizi.fill({ waiting: true })
        try {
          const res = await fetch('https://api.sanybeach.com/api/lotti/' + this.lotto)
          const lottoJson = await res.json()
          this.tabs.push(...(Array.from(new Set(lottoJson.lotto.servizi.map(servizio => servizio.categoria)))))
          sessionStorage._idLotto = this.lotto
          this.listaServizi = []
          this.listaServizi.push(...lottoJson.lotto.servizi.map((servizio) => {
            return {
              categoria: servizio.categoria,
              nome: servizio.nome,
              url: servizio.url,
              img: this.getMeaningfulUrl(servizio.url),
              btnText: this.$i18n.t(`${servizio.categoria}.btn`),
            }
          }))
        } catch (e) {
          this.lottoErrato = true
          this.lotto = null
        }
      }
    },
    methods: {
      getMeaningfulUrl (url) {
        const http = 'https://'
        const web = 'www'
        url = url.substr(url.indexOf(http) + http.length)
        if (url.indexOf(web) > -1) {
          url = url.substr(url.indexOf(web) + web.length + 1)
        }
        const subroutes = url.split('/').filter(val => val !== '')
        if (subroutes.length > 0) {
          for (let i = 0; i < subroutes.length; i++) {
            subroutes[i] = subroutes[i].substr(0, subroutes[i].indexOf('.'))
          }
          return subroutes.join('-')
        } else {
          return subroutes[0].substr(0, subroutes[0].indexOf('.'))
        }
      },
    },
  }
</script>

<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
<i18n>
{
  "en": {
    "no-services": "No services yet available in your area",
    "qr": "Scan a QR code to view the available services",
    "lottoErrato": "This area does not exist",
    "tabs": {
      "ristorazione":{
        "name": "Restaurants",
        "btn": "Order"
      },
      "prenotazione_ombrellone": {
        "name": "Book a sun umbrella",
        "btn": "Book"
      },
      "all": {
        "name": "All"
      }
    }
  },
  "it": {
    "no-services": "Nessun servizio ancora disponibile nella tua zona",
    "qr": "Inquadra un codice QR per visualizzare i servizi disponibili",
    "lottoErrato": "Lotto inesistente",
    "tabs": {
      "ristorazione":{
        "name": "Ristorazione",
        "btn": "Ordina"
      },
      "prenotazione_ombrellone": {
        "name": "Prenota un ombrellone",
        "btn": "Prenota"
      },
      "all": {
        "name": "Tutti"
      }
    }
  }
}
</i18n>
